import modules from '../../.config/modules';

// wraps following code with trycatch
try {
  // Following code block marks modules as to be loaded locally
  if (process.env.LOCAL_RUN == true) {
    window[`webPackLocalContainer`] = true;
    Object.keys(modules).forEach((v, i, a) => {
      if (modules[v].isExternal) {
        return;
      }
      window[`webPackLocalEntry_${v}`] = true;
    });
  }
} catch (error) {
}

import('./bootstrap');
