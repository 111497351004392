module.exports = {
  container: {
    port: 50000,
  },
  accounting: {
    port: 50001,
    isExternal: true,
  },
  authorization: {
    port: 50003,
    isExternal: true,
  },
  catalog: {
    port: 50004,
    isExternal: true,
  },
  customer: {
    port: 50005,
    isExternal: true,
  },
  dashboard: {
    port: 50006,
  },
  deposit: {
    port: 50007,
    isExternal: true,
  },
  fx: {
    port: 50008,
    isExternal: true,
  },
  identity: {
    port: 50009,
    isExternal: true,
  },
  loan: {
    port: 50010,
    isExternal: true,
  },
  metadata: {
    port: 50011,
    isExternal: true,
  },
  notification: {
    port: 50012,
    isExternal: true,
  },
  saga: {
    port: 50013,
    isExternal: true,
  },
  telemetry: {
    port: 50014,
    isExternal: true,
  },
  tenantsettings: {
    port: 50015,
    isExternal: true,
  },
  transfers: {
    port: 50016,
    isExternal: true,
  },
  financialreporting: {
    port: 50017,
    isExternal: true,
  },
  financialanalysis: {
    port: 50018,
    isExternal: true,
  },
  adcbackoffice: {
    port: 50019,
    isExternal: true,
  },
  financialintelligence: {
    port: 50020,
    isExternal: true,
  },
  cheque: {
    port: 50021,
    isExternal: true,
  },
  noncashloan: {
    port: 50022,
    isExternal: true,
  },
  component: {
    port: 50023,
  },
  foreigntrade: {
    port: 50024,
    isExternal: true,
  },
  // neovapoc: {
  //   port: 50025,
  //   isExternal: true,
  // },
  // misyonpoc: {
  //   port: 50026, // may change
  //   isExternal: true,
  // },
  dms: {
    port: 50027,
    isExternal: true,
  },
  fund: {
    port: 50028,
    isExternal: true,
  },
  mernis: {
    port: 50029,
    isExternal: true,
  },
  eft: {
    port: 50030,
    isExternal: true,
  },
  kkbrm: {
    port: 50031,
    isExternal: true,
  },
  transferstr: {
    port: 50032,
    isExternal: true,
  },
  limit: {
    port: 50041,
    isExternal: true,
  },
  collateral: {
    port: 50042,
    isExternal: true,
  },
  incentiveloan: {
    port: 50045,
    isExternal: true,
  },
  channelmanagement: {
    port: 50046,
    isExternal: true,
  },
  playground: {
    port: 50047,
  },
  paymentdocument: {
    port: 50049,
    isExternal: true,
  },
  card: {
    port: 50050,
    isExternal: true,
  },
  legalnotice: {
    port: 50051,
    isExternal: true,
  },
  tradefinance: {
    port: 50052,
    isExternal: true,
  },
  crm: {
    port: 50053,
    isExternal: true,
  },
  payments: {
    port: 50054,
    isExternal: true,
  },
  cashmanagement: {
    port: 50055,
    isExternal: true,
  },
  charge: {
    port: 50056,
    isExternal: true,
  },
  promissorynote: {
    port: 50057,
    isExternal: true,
  },
  edistraint: {
    port: 50058,
    isExternal: true,
  },
  pos: {
    port: 50059,
    isExternal: true,
  },
  microfinance: {
    port: 51010,
    isExternal: true
  },

  /*************/
  /** on-prems */
  /*************/
  /** misyon */
  miexchange: {
    port: 50101,
    isExternal: true,
  },
  mibanking: {
    port: 50102,
    isExternal: true,
  },
  midepositbrokerage: {
    port: 50103,
    isExternal: true,
  },
  micustody: {
    port: 50104,
    isExternal: true,
  },
  mimxpaltform: {
    port: 50105,
    isExternal: true,
  },
  miordertransmit: {
    port: 50106,
    isExternal: true,
  },
  misecurities: {
    port: 50107,
    isExternal: true,
  },
  micrm: {
    port: 50108,
    isExternal: true,
  },
  mxbanking: {
    port: 50109,
    isExternal: true,
  },
  mxdeposit: {
    port: 50110,
    isExternal: true,
  },
  mxtransfers: {
    port: 50111,
    isExternal: true,
  },
  mxdefinitions: {
    port: 50112,
    isExternal: true,
  },
  micustodyportal: {
    port: 50113,
    isExternal: true,
  },
  mxcore: {
    port: 50114,
    isExternal: true,
  },
  micryptocustody: {
    port: 50115,
    isExternal: true,
  },
  micreditmarket: {
    port: 50116,
    isExternal: true,
  },
  miportaldepositbrokerage: {
    port: 50117,
    isExternal: true,
  },
  miportalcreditmarket: {
    port: 50118,
    isExternal: true,
  },
  mxaccounting: {
    port: 50119,
    isExternal: true,
  },
  mxcustomer: {
    port: 50120,
    isExternal: true,
  },
  mxdealer: {
    port: 50121,
    isExternal: true,
  },
  mxpayments: {
    port: 50122,
    isExternal: true,
  },
  mxproduct: {
    port: 50123,
    isExternal: true,
  },
  /** neova */
  hasar360: {
    port: 50201,
    isExternal: true,
  },
  /** dkb */
  dkkernel: {
    port: 50301,
    isExternal: true,
  },
  dkenquiryagency: {
    port: 50302,
    isExternal: true,
  },
  dkbanking: {
    port: 50303,
    isExternal: true,
  },
  dkloan: {
    port: 50304,
    isExternal: true,
  },
  dktreasury: {
    port: 50305,
    isExternal: true,
  },
  dktransfer: {
    port: 50306,
    isExternal: true,
  },
  dkpaymentsystem: {
    port: 50307,
    isExternal: true,
  },
};
